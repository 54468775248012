.main-form {
    .form-group {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    input {
        border-width: 2px;
        border-style: solid;
        border-color: #333333;
        padding: 12px 15px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        
        &::placeholder {
            font-weight: 400;
        }

        &.error {
            border-color: #e74c3c;
        }
    }
}