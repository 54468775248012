body,
button,
input,
textarea,
a {
    font-family: "Poppins", sans-serif;
}

body.overflow-h {
    
    @media (max-width: 768px) {
        overflow: hidden !important;
    }
}

body.dialog-show {
    overflow: hidden !important;
}

html > body.full-height,
html > body.full-height > #root {
    height: 100%;
}

// .row [class^='col-'] {
//     margin: 3px 5px; // chenge the size of the gaps here
//   }

.bootstrap-wrapper {
    .container.max-w-fluid {
        max-width: none !important;
        padding-left: 0;
        padding-right: 0;
    }
}
